import React from "react";
import Select from "react-select";

const Filter = (props) => {
  let DEFAULT_ECOSYSTEM_PLACEHOLDER = "Select ecosystem";
  let DEFAULT_SUBECOSYSTEM_PLACEHOLDER = "Search by contributing ecosystem";
  let DEFAULT_MUNICIPALITY_PLACEHOLDER = "Search by municipality";

  const handleEcosystemChange = (option) => props.handleEcosystemChange(option);

  const handleSubecosystemChange = (option) =>
    props.handleSubecosystemChange(option);

  const handleMunicipalityChange = (option) =>
    props.handleMunicipalityChange(option);

  const optionsHash = (options) => {
    return options
      .map((option) => {
        return { value: option, label: option };
      })
      .sort();
  };

  const optionsHashStories = (options) => {
    let storyNames = options.map((i) => i["Name of Municipality"]);
    storyNames = [...new Set(storyNames)].sort();
    return storyNames.map((option) => {
      return { value: option, label: option };
    });
  };

  return (
    <React.Fragment>
      <Select
        className="ecosystemFilter"
        classNamePrefix="select"
        value={optionsHash([props.searchEcosystem])}
        onChange={handleEcosystemChange}
        isClearable={props.searchEcosystem !== DEFAULT_ECOSYSTEM_PLACEHOLDER}
        isSearchable={true}
        name="search-ecosystems"
        options={optionsHash(props.ecosystems)}
      />
      <Select
        className="subEcosystemFilter"
        classNamePrefix="select"
        value={optionsHash([props.searchSubEcosystem])}
        onChange={handleSubecosystemChange}
        isClearable={
          props.searchSubEcosystem !== DEFAULT_SUBECOSYSTEM_PLACEHOLDER
        }
        isSearchable={true}
        isDisabled={props.itemsDisabled}
        name="search-subecosystems"
        options={optionsHash(props.subEcosystemOptions)}
      />
      <Select
        className="municipalityFilter"
        classNamePrefix="select"
        value={optionsHash([props.searchMunicipality])}
        onChange={handleMunicipalityChange}
        isClearable={
          props.searchMunicipality !== DEFAULT_MUNICIPALITY_PLACEHOLDER
        }
        isSearchable={true}
        isDisabled={props.itemsDisabled}
        name="search-municipalities"
        options={optionsHashStories(props.allStories)}
      />
    </React.Fragment>
  );
};

Filter.defaultProps = {
  ecosystems: [],
  searchMap: {},
};

export default Filter;
